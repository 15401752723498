import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ConversionRateDoctor from "../components/templates/ConversionRateDoctor"

const practiceConversions = [
  {type: 'canine', name: 'Rabies Vaccination', percent: 33},
  {type: 'canine', name: 'DHPP Vaccination', percent: 40},
  {type: 'canine', name: 'Leptospirosis Vaccination', percent: 45},
  {type: 'canine', name: 'Bordetella Vaccination', percent: 28},
  {type: 'canine', name: 'Lyme Vaccination', percent: 78},
  {type: 'canine', name: 'Tick Pathogen Test', percent: 45},
  {type: 'canine', name: 'Heartworm Test', percent: 56},
  {type: 'canine', name: 'Fecal Test', percent: 62},
  {type: 'canine', name: 'Wellness Bloodwork', percent: 5},
  {type: 'canine', name: 'Dental Prophylaxis', percent: 9},
  {type: 'canine', name: 'Internal Parasite Coverage', percent: 67},
  {type: 'canine', name: 'External Parasite Coverage', percent: 23},
  {type: 'feline', name: 'Rabies Vaccination', percent: 33},
  {type: 'feline', name: 'FVRCP Vaccination', percent: 40},
  {type: 'feline', name: 'Feline Leukemia Vaccination', percent: 45},
  {type: 'feline', name: 'Fecal Test', percent: 62},
  {type: 'feline', name: 'Wellness Bloodwork', percent: 5},
  {type: 'feline', name: 'Dental Prophylaxis', percent: 9},
  {type: 'feline', name: 'Internal Parasite Coverage', percent: 67},
  {type: 'feline', name: 'External Parasite Coverage', percent: 23},
];

const doctors = [
  {
    name: 'Dr. Green',
    totalPatients: 125,
    conversions: [
      {type: 'canine', name: 'Rabies Vaccination', total: 50, successful: 3},
      {type: 'canine', name: 'DHPP Vaccination', total: 20, successful: 5},
      {type: 'canine', name: 'Leptospirosis Vaccination', total: 55, successful: 28},
      {type: 'canine', name: 'Bordetella Vaccination', total: 102, successful: 58},
      {type: 'canine', name: 'Lyme Vaccination', total: 42, successful: 24},
      {type: 'canine', name: 'Tick Pathogen Test', total: 20, successful: 8},
      {type: 'canine', name: 'Heartworm Test', total: 57, successful: 31},
      {type: 'canine', name: 'Fecal Test', total: 120, successful: 70},
      {type: 'canine', name: 'Wellness Bloodwork', total: 100, successful: 5},
      {type: 'canine', name: 'Dental Prophylaxis', total: 10, successful: 1},
      {type: 'canine', name: 'Internal Parasite Coverage', total: 55, successful: 35},
      {type: 'canine', name: 'External Parasite Coverage', total: 20, successful: 6},
      {type: 'feline', name: 'Rabies Vaccination', total: 50, successful: 3},
      {type: 'feline', name: 'FVRCP Vaccination', total: 20, successful: 5},
      {type: 'feline', name: 'Feline Leukemia Vaccination', total: 55, successful: 28},
      {type: 'feline', name: 'Fecal Test', total: 120, successful: 70},
      {type: 'feline', name: 'Wellness Bloodwork', total: 100, successful: 5},
      {type: 'feline', name: 'Dental Prophylaxis', total: 10, successful: 1},
      {type: 'feline', name: 'Internal Parasite Coverage', total: 55, successful: 35},
      {type: 'feline', name: 'External Parasite Coverage', total: 20, successful: 6},
    ]
  },
  {
    name: 'Dr. Jones',
    totalPatients: 100,
    conversions: [
      {type: 'canine', name: 'Rabies Vaccination', total: 60, successful: 7},
      {type: 'canine', name: 'DHPP Vaccination', total: 10, successful: 7},
      {type: 'canine', name: 'Leptospirosis Vaccination', total: 45, successful: 24},
      {type: 'canine', name: 'Bordetella Vaccination', total: 112, successful: 52},
      {type: 'canine', name: 'Lyme Vaccination', total: 40, successful: 14},
      {type: 'canine', name: 'Tick Pathogen Test', total: 30, successful: 10},
      {type: 'canine', name: 'Heartworm Test', total: 52, successful: 31},
      {type: 'canine', name: 'Fecal Test', total: 110, successful: 55},
      {type: 'canine', name: 'Wellness Bloodwork', total: 92, successful: 14},
      {type: 'canine', name: 'Dental Prophylaxis', total: 12, successful: 2},
      {type: 'canine', name: 'Internal Parasite Coverage', total: 45, successful: 28},
      {type: 'canine', name: 'External Parasite Coverage', total: 23, successful: 8},
      {type: 'feline', name: 'Rabies Vaccination', total: 56, successful: 4},
      {type: 'feline', name: 'FVRCP Vaccination', total: 25, successful: 7},
      {type: 'feline', name: 'Feline Leukemia Vaccination', total: 51, successful: 23},
      {type: 'feline', name: 'Fecal Test', total: 140, successful: 80},
      {type: 'feline', name: 'Wellness Bloodwork', total: 120, successful: 4},
      {type: 'feline', name: 'Dental Prophylaxis', total: 12, successful: 2},
      {type: 'feline', name: 'Internal Parasite Coverage', total: 51, successful: 37},
      {type: 'feline', name: 'External Parasite Coverage', total: 23, successful: 6},
    ]
  },
  {
    name: 'Dr. Smith',
    totalPatients: 150,
    conversions: [
      {type: 'canine', name: 'Rabies Vaccination', total: 40, successful: 3},
      {type: 'canine', name: 'DHPP Vaccination', total: 25, successful: 5},
      {type: 'canine', name: 'Leptospirosis Vaccination', total: 45, successful: 23},
      {type: 'canine', name: 'Bordetella Vaccination', total: 112, successful: 57},
      {type: 'canine', name: 'Lyme Vaccination', total: 46, successful: 26},
      {type: 'canine', name: 'Tick Pathogen Test', total: 25, successful: 9},
      {type: 'canine', name: 'Heartworm Test', total: 51, successful: 30},
      {type: 'canine', name: 'Fecal Test', total: 110, successful: 70},
      {type: 'canine', name: 'Wellness Bloodwork', total: 120, successful: 6},
      {type: 'canine', name: 'Dental Prophylaxis', total: 12, successful: 1},
      {type: 'canine', name: 'Internal Parasite Coverage', total: 50, successful: 30},
      {type: 'canine', name: 'External Parasite Coverage', total: 25, successful: 7},
      {type: 'feline', name: 'Rabies Vaccination', total: 59, successful: 4},
      {type: 'feline', name: 'FVRCP Vaccination', total: 23, successful: 5},
      {type: 'feline', name: 'Feline Leukemia Vaccination', total: 50, successful: 25},
      {type: 'feline', name: 'Fecal Test', total: 100, successful: 60},
      {type: 'feline', name: 'Wellness Bloodwork', total: 130, successful: 7},
      {type: 'feline', name: 'Dental Prophylaxis', total: 15, successful: 2},
      {type: 'feline', name: 'Internal Parasite Coverage', total: 50, successful: 30},
      {type: 'feline', name: 'External Parasite Coverage', total: 27, successful: 6},
    ]
  },
];
const ConversionRateDoctorPage = () => (

  <Layout>
    <SEO title="Conversion Rate by Doctor" />
    <div><Link to="/">Go back to the homepage</Link></div>
    <ConversionRateDoctor
      practiceConversions={practiceConversions}
      doctors={doctors}
      practice='ABC Animal Hospital'
      date='March 2020'
      type='Canine'
    />
  </Layout>
)

export default ConversionRateDoctorPage
