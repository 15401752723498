import React from "react"
import styled from "styled-components"

import fonts from "../../fonts/fonts.css"

import ComplianceTrackerSelect from "../molecules/ComplianceTrackerSelect"
import QuestionMarkIcon from "../atoms/QuestionMarkIcon/QuestionMarkIcon"

import vsLogo from "../../images/VS_horizontal_BLACK.png"
import blueAppendix from "../../images/blue-appendix.svg"

const ConversionRateDoctor = ({practiceConversions, doctors, practice, date, type}) => {
  const doctorSelectItems = doctors.map(doctor => `${doctor.name} (${doctor.totalPatients} patients)`);

  const valueSign = value => {
    if (value > 0) {
      return '+';
    } else if (value < 0) {
      return '-';
    }

    return '';
  };

  return (
    <ConversionRateDoctorContainer>
      <Header>
        <div>{practice}</div>
        <div>{date}</div>
        <Logo src={vsLogo}/>
      </Header>
      <TopSection>
        <Description>
          <Title>
            <div>Conversion Rate by Doctor – {type}</div>
          </Title>
          <div>How successful was a given doctor at converting non-compliant patients into compliant patients?</div>
        </Description>
        <Legend>
          <LegendItem color='#8EC641'>
            <TriangleIcon/>
            At Or Above Practice Rate
          </LegendItem>
          <LegendItem color='#EBBA1E'>
            <TriangleIcon down={true}/>
            Slightly Below Practice Rate
          </LegendItem>
          <LegendItem color='#EE2526'>
            <TriangleIcon down={true}/>
            Significantly Below Practice Rate
          </LegendItem>
        </Legend>
        <Appendix>
          <AppendixIcon src={blueAppendix}/>
          <AppendixLink>
            <span>- All Doctor Breakdown</span>
          </AppendixLink>
        </Appendix>
      </TopSection>
      <SelectContainer>
        <ComplianceTrackerSelect items={doctorSelectItems}/>
        <QuestionMarkIcon text={'The total patient count includes all ' + type.toLowerCase() +
                          ' wellness patients seen by the provider in the current month who received at least one medical service'}/>
      </SelectContainer>
      <TableContainer>
        <ProcedureContainer>
          <ProcedureTitle>Procedure</ProcedureTitle>
          <SubtitleHeight>
          </SubtitleHeight>
          {doctors[0].conversions.filter(function(conversion) {return conversion.type === type.toLowerCase()}).map((conversion, index) => {
            return (
              <TableRow key={index} index={index}>
                {conversion.name}
              </TableRow>
            )
          })}
        </ProcedureContainer>
        <ConversionRateContainer>
          <ConversionRateTitle>Conversion Rate</ConversionRateTitle>
          <ColumnSubtitle>
            <PracticeSubColumn>
              <ConversionRateSubtitle>Practice</ConversionRateSubtitle>
            </PracticeSubColumn>
            <DoctorSubColumn>
              <ConversionRateSubtitle>Doctor</ConversionRateSubtitle>
            </DoctorSubColumn>
            <CompareSubColumn>
              <ConversionRateSubtitle>Doctor Compared to Practice</ConversionRateSubtitle>
            </CompareSubColumn>
          </ColumnSubtitle>
          <ColumnData>
            <PracticeSubColumn>
              {practiceConversions.filter(function(conversion) {return conversion.type === type.toLowerCase()}).map((conversion, index) => {
                return (
                  <TableRow key={index} index={index}>
                    {conversion.percent + '%'}
                  </TableRow>
                )
              })}
            </PracticeSubColumn>
            <DoctorSubColumn>
              {doctors[0].conversions.filter(function(conversion) {return conversion.type === type.toLowerCase()}).map((conversion, index) => {
                return (
                  <TableRow key={index} index={index}>
                    {Math.round(100 * conversion.successful / conversion.total) + '%'}
                  </TableRow>
                )
              })}
            </DoctorSubColumn>
            <CompareSubColumn>
              {doctors[0].conversions.filter(function(conversion) {return conversion.type === type.toLowerCase()}).map((conversion, index) => {
                return (
                  <CompareTableRow key={index} index={index} value={Math.round(100 * conversion.successful / conversion.total) - practiceConversions[index].percent}>
                    <ValueSignDiv>
                      {valueSign(Math.round(100 * conversion.successful / conversion.total) - practiceConversions[index].percent)}
                    </ValueSignDiv>
                    <ValueDiv>
                      {Math.abs(Math.round(100 * conversion.successful / conversion.total) - practiceConversions[index].percent) + ' %'}
                    </ValueDiv>
                  </CompareTableRow>
                )
              })}
            </CompareSubColumn>
          </ColumnData>
        </ConversionRateContainer>
        <SuccessfulConversionsContainer>
          <SuccessfulConversionsTitle>Successful Conversions</SuccessfulConversionsTitle>
          <SubtitleHeight>
          </SubtitleHeight>
          {doctors[0].conversions.filter(function(conversion) {return conversion.type === type.toLowerCase()}).map((conversion, index) => {
            return (
              <TableRow key={index} index={index}>
                {conversion.successful + ' / ' + conversion.total}
              </TableRow>
            )
          })}
        </SuccessfulConversionsContainer>
      </TableContainer>
    </ConversionRateDoctorContainer>
  )
}

export default ConversionRateDoctor;

const ConversionRateDoctorContainer = styled.div`
  max-width: 1170px;
  margin: auto;
  padding 0 15px;
  font-family: HelveticaNeueLTStd, sans-serif;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 15px 0;
  padding-bottom: 3px;
  border-bottom: solid 0.5pt #3C3B3B;
  font-size: 10pt;
  font-weight: 300;
`

const Logo = styled.img`
  width: 80px;
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px 20px;
  padding: 20px 0 40px;
  border-bottom: solid 6pt #B2B4B7;
`

const Description = styled.div`
  flex-basis: 50%;
  font-size: 12pt;
  font-weight: 300;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 25pt;
  font-weight: bold;
  color: #8EC641;
`

const Legend = styled.div`
`

const LegendItem = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.color};
`
const TriangleIcon = styled.div`
  display: inline-block;
  margin-right: 5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-${props => props.down === true ? 'top' : 'bottom'}: 12px solid ${props => props.color};
`

const Appendix = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  font-size: 8pt;
  font-weight: bold;
  color: #5978CF;
  text-decoration: underline;
`

const AppendixIcon = styled.img`
  width: 30px;
  margin-right: 5px;
`

const AppendixLink = styled.div`
  padding-bottom: 5px;
  
  > span {
    cursor: pointer;
  }
`

const SelectContainer = styled.div`
  margin: 0 15px 50px 0;
`

const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
`

const ProcedureContainer = styled.div`
  flex: 1;
  text-align: right;
`

const ConversionRateContainer = styled.div`
  flex: 2;
  text-align: center;
`

const SuccessfulConversionsContainer = styled.div`
  flex: 1;
  text-align: center;
`

const ProcedureTitle = styled.div`
  background-color: #E6EAF6;
  padding: 10px 40px;
  font-weight: bold;
  border-right: 10px solid #fff;
`

const ConversionRateTitle = styled.div`
  background-color: #E6EAF6;
  padding: 10px 20px;
  font-weight: bold;
`

const SuccessfulConversionsTitle = styled.div`
  background-color: #E6EAF6;
  padding: 10px 20px;
  font-weight: bold;
  border-left: 10px solid #fff;
`

const ColumnSubtitle = styled.div`
  display: flex;
`

const ColumnData = styled.div`
  display: flex;
  border: 1px solid #333;
`

const SubtitleHeight = styled.div`
  height: 57px;
`

const ConversionRateSubtitle = styled.div`
  padding: 20px 0;
  height: 14px;
`

const PracticeSubColumn = styled.div`
  flex: 2;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`

const DoctorSubColumn = styled.div`
  flex: 1;
  height: 56px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`

const CompareSubColumn = styled.div`
  flex: 2;
  height: 56px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`

const TableRow = styled.div`
  padding: 8px 40px;
  font-size: 12px;
  font-weight: normal;
  background-color: ${props => props.index % 2 === 0 ? '#F1F1F1' : '#FFF'};
`

const CompareTableRow = styled.div`
  padding: 6px 40px;
  font-size: 15px;
  color: ${props => props.value >= 0 ? '#8EC641' : '#EBBA1E'};
  color: ${props => props.value <= -10 ? '#EE2526' : ''};
  background-color: ${props => props.index % 2 === 0 ? '#F1F1F1' : '#FFF'};
`

const ValueSignDiv = styled.div`
  display: inline-block;
  width: 20px;
`

const ValueDiv = styled.div`
  display: inline-block;
  width: 50px;
`
